import * as Sentry from "@sentry/gatsby"
import { BrowserTracing } from "@sentry/tracing"

Sentry.init({
    dsn: `https://9ac605432638462db60b03b28f22e52a@o1378144.ingest.sentry.io/6689750`,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,

    integrations: [
        new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        }),
        new BrowserTracing(),
    ],
})